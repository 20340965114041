import { Card, CardHeader, CardMedia } from '@mui/material';
import type { CardProps } from '@mui/material';
import { labelForEnum } from '../utils/libs';

export type UniformCardProps = {
  uniform: {
    title: string | null | undefined;
    description: string | null | undefined;
    jobType: string | null | undefined;
    photo: string | null | undefined;
  };
} & Omit<CardProps, 'children'>;

const UniformCard = ({ uniform, ...CardProps }: UniformCardProps) => (
  <Card {...CardProps}>
    <CardMedia image={uniform.photo ?? undefined} sx={{ height: 192 }} />
    <CardHeader title={uniform.title} subheader={labelForEnum('job_type', uniform.jobType)} />
  </Card>
);

export default UniformCard;
